import { Link, useNavigate, useParams } from "react-router-dom";
import { accessNames, boldifyName, checkFileValidity, getSimpleName, round } from "../helpers";
import { useEffect, useState } from "react";
import { Checkbox, FileInput, Label, Radio, Select, Textarea } from "flowbite-react";
import { useForm } from "react-hook-form"
import { authenticatedApi } from "../Api";
import toast from "react-hot-toast";
import { useAuth } from "../AuthProvider";
import ConfirmDeleteFile from "./ConfirmDeleteFile";
import { DatePicker } from "./DatePicker";
import { parse, parseISO } from "date-fns";

export const AssessmentAdditionalInformation = (props) => {
    const auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();

    const {
        uuid = null,
        standardId = null,
        keyIndicatorId = null,
        indicatorId = null
    } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    let defaultValues = {};
    let thisIndicatorEntry = null;
    if(props.entry) {
        thisIndicatorEntry = props.entry.entry.find(obj => obj.standard_id === 'additional-information') ?? null;

        if(thisIndicatorEntry) {
            defaultValues = thisIndicatorEntry;
            // fix dates
            // console.log(defaultValues);
            props.form.additional_schema.map((question, index) => {
                if(question.type === 'date' && typeof defaultValues[`lp_answer_${question.id}`] === 'string') {
                    // console.log('is a date field '+question.id);
                    // console.log(""+defaultValues[`lp_answer_${question.id}`]);
                    defaultValues[`lp_answer_${question.id}`] = parseISO(defaultValues[`lp_answer_${question.id}`]);
                    // console.log(defaultValues[`lp_answer_${question.id}`]);
                }
            });
        }
    }

    const { control, register, reset, watch, handleSubmit, getValues, setValue, trigger, formState: { errors, isValid, submitCount } } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,//{}
    });

    /*
    // handle resetting form when indicator changes
    useEffect(() => {
        // build initial form state
        let thisIndicatorEntry = null;
        if(props.entry) {
            thisIndicatorEntry = props.entry.entry.find(obj => obj.indicator_id === parseInt(indicatorId)) ?? null;
        }

        if(thisIndicatorEntry === null) {
            thisIndicatorEntry = {
                indicator_id: parseInt(indicatorId) ?? null,
                lp_answer: null,
                lp_notes: null,
                cp_answer: null,
                cp_notes: null,
                msa_answer: null,
                msa_notes: null,
            };
        }
        reset(thisIndicatorEntry);
    }, [indicatorId]);
    */

    if(!props.form.additional_schema || (Array.isArray(props.form.additional_schema) && !props.form.additional_schema.length))
    {
        return (<em>This form does not require any additional information.</em>);
    }

    const getNextLink = () => {
        let nextText = 'Continue';
        let nextLink = '';
        // first check if we have any more indicators in this key indicator

        /*
        // const indicatorIndex = props.keyIndicator.indicators.map(e => e.id).indexOf('stevie');
        let indicatorIndex = props.keyIndicator.indicators.findIndex(obj => obj.id === parseInt(indicatorId));//.indexOf(props.indicator);
        if(nextLink === '' && indicatorIndex > -1 && indicatorIndex+1 < props.keyIndicator.indicators.length) {
            let nextIndicator = props.keyIndicator.indicators[indicatorIndex+1];
            nextText = nextIndicator.name;
            nextLink = `/forms/${props.form.uuid}/${props.standard.id}/${props.keyIndicator.id}/${nextIndicator.id}`;
        }

        // then check if we have any more key indicators in this standard
        let keyIndicatorIndex = props.standard.key_indicators.indexOf(props.keyIndicator);
        if(nextLink === '' && keyIndicatorIndex > -1 && keyIndicatorIndex+1 < props.standard.key_indicators.length) {
            let nextKeyIndicator = props.standard.key_indicators[keyIndicatorIndex+1];
            nextText = nextKeyIndicator.name;
            nextLink = `/forms/${props.form.uuid}/${props.standard.id}/${nextKeyIndicator.id}`;
        }

        // then check if we have any more standards in this form
        let standardIndex = props.form.standards.indexOf(props.standard);
        if(nextLink === '' && standardIndex > -1 && standardIndex+1 < props.form.standards.length) {
            let nextStandard = props.form.standards[standardIndex+1];
            nextText = getSimpleName(nextStandard.name);
            nextLink = `/forms/${props.form.uuid}/${nextStandard.id}`;
        }
        */

        // then go to review
        if(nextLink === '') {
            nextText = 'Summary';
            nextLink = `/forms/${props.form.uuid}/summary`;
        }

        return {nextLink, nextText};
    }

    const onDeleteFile = (newMedia) => {
        const {media, ...prevEntry} = props.entry;
        prevEntry.media = newMedia;
        props.setEntry(prevEntry);
    };

    const onFileChange = (type, event) => {
        if(!checkFileValidity(event)) {
            return;
        }

        const formData = new FormData();
        formData.append('section', type);
        // console.log(type);
        formData.append(type, event.target.files[0]);

        authenticatedApi.post('form-entries/'+props.entry.id+'/media', formData).then(response => {
            if(response.data && response.data.media && response.data.media.length) {
                const {media, ...prevEntry} = props.entry;
                prevEntry.media = response.data.media;
                props.setEntry(prevEntry);
                event.target.value = ''; // reset form
            }
        });
    };

    const onSubmit = (data) => {
        // console.log(data);
        setIsLoading(true);
        const {nextText, nextLink} = getNextLink();

        let _answers = props.entry ? props.entry.entry : [];
        let _data = data;

        // fix strings
        for(const answerKey in _data) {
            if(_data[answerKey] === 'true') {
                _data[answerKey] = true;
            }
            if(_data[answerKey] === 'false') {
                _data[answerKey] = false;
            }
        }

        _data.standard_id = 'additional-information';

        // updating existing answer
        let _index = _answers.findIndex(obj => obj.standard_id === _data.standard_id);
        if(_index !== -1 /*_entry.find(obj => obj.indicator_id === props.indicator.id)*/) {
            _answers[_index] = _data;
        }
        // create new answer
        else
        {
            _answers.push(_data);
        }

        let formEntry;
        if(props.entry) {
            formEntry = {
                entry: _answers,
            };
        } else {
            formEntry = {
                form_id: props.form.id,
                community_id: auth.currentCommunityId,
                status: 'draft',
                entry: _answers,

                form_schema: null,
                entry_schema: null,
            };
        }

        let saveFn = null;
        if(props.entry) {
            saveFn = authenticatedApi.put('form-entries/'+props.entry.id, formEntry);
        } else {
            saveFn = authenticatedApi.post('form-entries', formEntry);
        }
        saveFn.then(response => {
            let _entry = response.data;
            _entry.entry = typeof _entry.entry === 'string' ? JSON.parse(_entry.entry) : _entry.entry;
            // _entry.entry = JSON.parse(_entry.entry);
            props.setEntry(_entry);
            // props.setEntry(response.data);

            toast.success('Saved!');
            navigate(nextLink);
        }).finally(() => {
            setIsLoading(false);
        });

        // todo: display error

        return true;
    };

    const ContinueButton = () => {
        const {nextText, nextLink} = getNextLink();

        if(nextLink === '') {
            return null;
        }

        let buttonClasses = [];
        if(!isValid) {
            buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
        } else if(isLoading) {
            buttonClasses.push('cursor-wait');
            buttonClasses.push('opacity-75');
        } else {
            buttonClasses.push('cursor-pointer');
        }

        return (<>
            <button
                type="submit"
                // onClick={onClick}
                className={`self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
            >
                {nextText}
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
        </>);
    }

    return (<>
        <p className="mb-3"><em>Please answer these additional questions.</em></p>

        {/* <h1 className="text-xl md:text-3xl font-semibold">{props.indicator.name}</h1> */}
        {/* <div className="max-w-4xl text-black" dangerouslySetInnerHTML={{__html: tweakIndicatorDescription(props.indicator.description) }}></div> */}

        <form onSubmit={handleSubmit(onSubmit)}>
        {props.form.additional_schema.map((question, index) => {
            const answerKey = `lp_answer_${question?.id ?? index}`; // todo: determine if this needs to allow cp/msa answers

            let questionRequired = !!(question?.required);
            let questionText = question.question;
            if(questionRequired) {
                let requiredText = ` <span class="text-red-800">(Required)</span>`;
                if(questionText.match(/<\/p>$/)) {
                    questionText = questionText.replace(/<\/p>$/, `${requiredText}</p>`);
                } else {
                    questionText += requiredText;
                }
            }

            let date_defaultValue;
            let date_onChange;
            let date_onBlur;
            let date_name;
            if(question.type === 'date') {
                const { onChange, onBlur, name: name } = register(answerKey);
                date_onChange = onChange;
                date_onBlur = onBlur;
                date_name = name;
                //date_defaultValue = defaultValues[answerKey] ?? new Date;
                date_defaultValue = watch(answerKey);
            }

            return (
                <div key={index} className={`px-6 py-3 mb-3 gap-3 rounded shadow bg-white`}>
                    <div className="mb-3" dangerouslySetInnerHTML={{__html: questionText}}></div>
                    {question.type === 'text' && (<>
                        <Textarea
                            id={answerKey}
                            className="p-3"
                            {...register(answerKey, {required: questionRequired})}
                        ></Textarea>
                    </>)}
                    {question.type === 'boolean' && (<div className="flex gap-4">
                        {['Yes', 'No'].map((answer, answerIndex) => (
                            <div key={answerIndex}  className="flex items-center gap-2">
                                <Radio
                                    {...register(`${answerKey}`, {required: questionRequired})}
                                    id={`${answerKey}_${answerIndex}`}
                                    value={answer.toLocaleLowerCase()}
                                />
                                <Label htmlFor={`${answerKey}_${answerIndex}`}>
                                {answer}
                                </Label>
                            </div>
                        ))}
                    </div>)}
                    {question.type === 'multichoice' && (<>
                        {question.options.map((answer, answerIndex) => (
                            <div key={answerIndex} className="flex items-center gap-2">
                                <Checkbox
                                    // id={`${answerKey}_${answerIndex}`}
                                    {...register(`${answerKey}`, /*{required: questionRequired}*/)}
                                    id={`${answerKey}_${answerIndex}`}
                                    value={answer}
                                />
                                <Label htmlFor={`${answerKey}_${answerIndex}`}>
                                {answer}
                                </Label>
                            </div>
                        ))}
                    </>)}
                    {question.type === 'choice' && (<>
                        <Select id={answerKey} {...register(answerKey, {required: questionRequired})}>
                            <option value="" disabled>Choose...</option>
                            {question.options.map(answer => <option value={answer} key={answer}>{answer}</option>)}
                        </Select>
                    </>)}
                    {question.type === 'date' && (<>
                        <DatePicker
                            id={answerKey}
                            {...register(answerKey, {required: questionRequired})}
                            isClearable
                            // required={questionRequired}

                            // onChange={date_onChange}
                            // onBlur={date_onBlur}
                            // name={date_name}
                            // defaultDate={date_defaultValue}
                            selected={date_defaultValue}
                            onChange={(e) => {
                                setValue(answerKey, e)
                                // console.log(e);
                                // setValue(answerKey);
                                // flowbite doesn't pass an event
                                date_onChange({target: {value: e}});
                            }}
                        />
                    </>)}
                    {question.type === 'file' && (<>
                        {/* <Label htmlFor={answerKey} value={"File"} /> */}
                        <FileInput
                            // helperText="Documentation is required for this indicator."
                            id={answerKey}
                            size={'sm'}
                            // required={questionRequired}
                            onChange={e => onFileChange(answerKey, e)}
                            helperText={'Files must be no larger than 50mb'}
                        />

                        {props.entry.media.filter(media => media.collection_name == answerKey).length > 0 && (
                            <table className="table-auto w-full rounded overflow-hidden bg-white mt-6">
                                <tbody>
                                {props.entry.media.filter(media => media.collection_name == answerKey).map((media, mediaIndex) => (
                                    <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-3 py-1.5 ${mediaIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={mediaIndex}>
                                        <td className="px-3 py-1.5"><a href={media.original_url} target="_blank">{media.file_name}</a></td>
                                        <td className="px-3 py-1.5 text-right"><ConfirmDeleteFile media={media} onDelete={onDeleteFile} /></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                    </>)}

                </div>
            );
        })}
        <ContinueButton />
        </form>
    </>);
};
