import { addMonths, differenceInCalendarDays, format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { useAuth } from "../AuthProvider";
import { apiUrl, authenticatedApi } from "../Api";
import CommunityPicker from "./CommunityPicker";
import PortalLoader from "./PortalLoader";
import { Link } from "react-router-dom";
import { useSettings } from "../SettingsProvider";
import { designations, getLinkForFirstUnfilledItem, locationString, round, sortBySequence } from "../helpers";

export default function AccreditationCards(props) {
    let auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();

    const settings = useSettings();

    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(null);
    const [entry, setEntry] = useState(null);
    const [progress, setProgress] = useState(0);
    const [allEntries, setAllEntries] = useState([]);

    const setup = () => {
        if(/*settings.isLoading || */!auth.currentCommunityId) {
            return;
        }

        setIsLoading(true);

        let promises = [];

        let getAllFormEntries = authenticatedApi.get(`form-entries/mine?community_id=${auth.currentCommunityId}`).then(response => {
            // console.log(response.data);
            setAllEntries(response.data.data);
        });
        promises.push(getAllFormEntries);

        if(settings.active_form) {
            let endpoint = 'forms/current';
            if(auth.currentCommunity && auth.currentCommunity.coordinating_program_id) {
                endpoint += '?coordinating_program_id='+auth.currentCommunity.coordinating_program_id;
            }
            // auth.currentCommunity.coordinating_program_id
            //${settings.active_form.uuid}
            //`forms/current?community_id=${auth.currentCommunityId}`
            let getForm = authenticatedApi.get(endpoint).then(response => {
                setForm(response.data);
            });

            promises.push(getForm);

            // todo: handle community
            let getFormEntry = authenticatedApi.get(`form-entries/mine?filter_mode=and&community_id=${auth.currentCommunityId}&form.uuid=${settings.active_form.uuid}`).then(response => {
                if(response.data.data.length) {
                    // set entry
                    let _entry = response.data.data[0];
                    _entry.entry = JSON.parse(_entry.entry);
                    const hasAdditionalInfo = _entry.form.additional_schema && _entry.form.additional_schema.length;
                    delete _entry.form; // clean it up a bit
                    setEntry(_entry);

                    // set progress
                    let indicatorsTotal = 0;
                    for(const standards of settings.active_form.standards.sort(sortBySequence)) {
                        for(const keyIndicators of standards.key_indicators.sort(sortBySequence)) {
                            indicatorsTotal+= keyIndicators.indicators.length;
                        }
                    }

                    let completedIndicatorsTotal = _entry.entry.filter(obj => obj.hasOwnProperty('indicator_id') && obj.lp_answer != null).length;

                    // add other sections
                    const otherSections = [
                        'overview',
                        'baseline-requirements',
                    ];
                    if(hasAdditionalInfo) {
                        otherSections.push('additional-information');
                    }
                    for(const otherSection of otherSections) {
                        indicatorsTotal++;
                        if(_entry.entry.find(obj => obj.standard_id === otherSection)) {
                            completedIndicatorsTotal++;
                        }

                        // let hasAnswer = _entry.entry.filter(obj => obj.hasOwnProperty('indicator_id') && obj.lp_answer != null).length;
                    }

                    /*
                    // add worksheet
                    indicatorsTotal++;
                    if(_entry.status && _entry.status !== 'draft') {
                        completedIndicatorsTotal++;
                    }
                    */
                    // let worksheetCompleted = _entry.entry.filter(obj => obj.hasOwnProperty('worksheet')/* && obj.lp_answer != null*/).length;
                    // completedIndicatorsTotal += worksheetCompleted;

                    setProgress(Math.min(100, round((completedIndicatorsTotal / indicatorsTotal) * 100)));
                } else {
                    setProgress(0);
                }
            });

            promises.push(getFormEntry);
        }


        Promise.all(promises).finally(() => {
            setIsLoading(false); // todo: move this into a catch statement
        });
    };

    // useEffect(setup, []);
    useEffect(setup, [settings.active_form, auth.currentCommunityId]);

    if(!auth.currentCommunityId) {
        return (<>
            <div className={`flex flex-col block bg-white shadow`}>
                <p className={`px-6 pt-3 mb-3 text-md font-semibold tracking-tight text-blue-500`}>Progress</p>
                <div className="px-6 pb-3">
                    <p className="mb-3 text-sm font-normal tracking-tight">To see progress, please choose a community.</p>
                    <CommunityPicker/>
                </div>
            </div>
        </>);
    }


    if(settings.isLoading || isLoading) {
        return (<>
            <div className="flex flex-col block shadow px-6 py-3">
                <PortalLoader text="Loading..." />
            </div>
        </>);
    }

    let classMap = {
        good: {
            bg: 'bg-white',
            title: 'text-blue-500',
            text: '',
        },
        warning: {
            bg: 'bg-yellow-50',
            title: 'text-yellow-700',
            text: 'text-black',
        },
        bad: {
            bg: 'bg-red-200',
            title: 'text-red-800',
            text: 'text-black',
        },
        success: {
            bg: 'bg-green-50',
            title: 'text-green-700',
            text: 'text-black',
        },
    };

    let endAt = form ? parseISO(/*settings.active_*/form.end_at) : new Date();
    let daysRemaining = differenceInCalendarDays(endAt, new Date());

    let classType = 'good';
    let bgClass = 'bg-white';
    if(daysRemaining <= 7) {
        classType = 'bad';
        bgClass = 'bg-red-200';
    } else if(daysRemaining <= '30') {
        classType = 'warning';
        bgClass = 'bg-yellow-50';
    }

    const {nextText: _nextText, nextLink} = getLinkForFirstUnfilledItem(accessLevel, form, entry);
    let nextText = _nextText;
    if(nextText !== 'Begin' && nextText !== 'View Entry') {
        nextText = 'Continue';
    }

    return (<>
        {settings.active_form && (<>
        {entry !== null && (<>
        {entry.status === 'accepted' && (
            <div className={`flex flex-col block shadow ${classMap['success'].bg} ${classMap['success'].text}`}>
            <p className={`px-6 pt-3 mb-3 text-md font-semibold tracking-tight ${classMap['success'].title}`}>Self-Assessment Complete</p>

            <p className="px-6 mb-3">
                Your self-assessment is complete and has been accepted!
                <br/><br/>
                The designation for {auth.currentCommunity.name} is: <span className="font-bold">{designations[auth.currentCommunity.designation] ?? designations['none']}</span>
                <br/><br/>
                <Link to={`/forms/${form.uuid}/summary`} className="self-start inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
                    View Final Assessment
                    <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </Link>
            </p>
        </div>
        )}
        {entry.status === 'needs-attention' && (
            <div className={`flex flex-col block shadow ${classMap['bad'].bg} ${classMap['bad'].text}`}>
            <p className={`px-6 pt-3 mb-3 text-md font-semibold tracking-tight ${classMap['bad'].title}`}>Information Needed</p>

            <p className="px-6 mb-3">
                Your self-assessment has been sent back to you for additional information and/or corrections. Please update your self-assessment and then re-submit.
            </p>
        </div>
        )}
        </>)}
        {(!entry || entry.status !== 'accepted') && (<>
        <div className={`flex flex-col block shadow ${classMap[classType].bg} ${classMap[classType].text}`}>
            <p className={`px-6 pt-3 mb-3 text-md font-semibold tracking-tight ${classMap[classType].title}`}>Deadline</p>

            <p className="px-6 mb-3">
                {daysRemaining >= 0 && (<><b>{`${daysRemaining} ${daysRemaining === 1 ? 'day' : 'days'}`}</b> remaining to complete this year's accreditation self-assessment</>)}
                {daysRemaining < 0 && (<><b>ACCREDITATION SELF-ASSESSMENT PAST DUE</b></>)}
            </p>
            <div className="mx-6 mb-3">
                <Link to={nextLink} className="self-start inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
                    {nextText}
                    <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </Link>
            </div>
        </div>
        <div className={`flex flex-col block bg-white shadow`}>
            <p className={`px-6 pt-3 mb-3 text-md font-semibold tracking-tight text-blue-500`}>Progress</p>
            <p className="px-6 mb-3">{progress}% Completed</p>

            <div className="bg-gray-200 h-2.5 mx-6 mb-3 rounded">
                <div className="h-2.5 rounded bg-gradient-to-r from-orange-300 to-orange-500" style={{width: progress+'%'}}></div>
            </div>

            {progress === 100 && ['draft', 'needs-attention'].indexOf(entry.status) !== -1 && (
                <div className="mx-6 mb-3 border-l-4 border-red-400 bg-red-100 p-4 font-medium text-sm">Don't forget to submit your self-assessment!</div>
            )}
        </div>
        </>)}
        </>)}

        {!settings.active_form && (<>
            <div className={`flex flex-col block bg-white shadow`}>
                <p className={`px-6 pt-3 mb-3 text-md font-semibold tracking-tight text-blue-500`}>Assessment</p>
                <div className="px-6 pb-3">
                    <p className="mb-3 text-sm font-normal tracking-tight">There is no active assessment at this time.</p>
                    {/* <CommunityPicker/> */}
                </div>
            </div>
        </>)}

        {allEntries.length > 0 && (<>
            <div className={`flex flex-col block bg-white shadow`}>
                <p className={`px-6 py-3 text-md font-semibold tracking-tight text-blue-500`}>Past Entries</p>
                {allEntries.map((entry, index) => (
                    <Link to={`/forms/${entry.form.uuid}/summary`}/* uuid */ className={`group self-start flex items-center text-md tracking-tight px-6 py-1  text-green-500 hover:text-green-600`} key={entry.id}>
                        <p className="text-md font-semibold tracking-tight flex-auto">{entry.form.name}</p>
                        {/* <p className="text-sm font-semibold tracking-tight text-green-500"><em className="text-gray-500">{latestMessage.user.name} wrote on {format(parseISO(latestMessage.created_at), 'MM/dd/yy')}</em></p> */}
                        {/* <p className="text-sm font-normal tracking-tight" dangerouslySetInnerHTML={{__html: latestMessage.message}}></p> */}
                        <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </Link>
                ))}

                <div className="px-6 pb-3">
                    {/* <p className="mb-3 text-sm font-normal tracking-tight">There is no active assessment at this time.</p> */}
                    {/* <CommunityPicker/> */}
                </div>
            </div>
        </>)}
    </>);
}
